import Vue from 'vue'
import { wrapFunctional } from './utils'

const components = {
  BBtn: () => import('../../components/BBtn.js' /* webpackChunkName: "components/b-btn" */).then(c => wrapFunctional(c.default || c)),
  BForm: () => import('../../components/BForm.js' /* webpackChunkName: "components/b-form" */).then(c => wrapFunctional(c.default || c)),
  ConstantSelect: () => import('../../components/ConstantSelect.vue' /* webpackChunkName: "components/constant-select" */).then(c => wrapFunctional(c.default || c)),
  DataTable: () => import('../../components/DataTable.vue' /* webpackChunkName: "components/data-table" */).then(c => wrapFunctional(c.default || c)),
  DataTableEdit: () => import('../../components/DataTableEdit.js' /* webpackChunkName: "components/data-table-edit" */).then(c => wrapFunctional(c.default || c)),
  DataTableExport: () => import('../../components/DataTableExport.vue' /* webpackChunkName: "components/data-table-export" */).then(c => wrapFunctional(c.default || c)),
  DatePicker: () => import('../../components/DatePicker.vue' /* webpackChunkName: "components/date-picker" */).then(c => wrapFunctional(c.default || c)),
  GooglePay: () => import('../../components/GooglePay.vue' /* webpackChunkName: "components/google-pay" */).then(c => wrapFunctional(c.default || c)),
  ImagePicker: () => import('../../components/ImagePicker.vue' /* webpackChunkName: "components/image-picker" */).then(c => wrapFunctional(c.default || c)),
  KContainer: () => import('../../components/KContainer.vue' /* webpackChunkName: "components/k-container" */).then(c => wrapFunctional(c.default || c)),
  ListTree: () => import('../../components/ListTree.vue' /* webpackChunkName: "components/list-tree" */).then(c => wrapFunctional(c.default || c)),
  ObjectList: () => import('../../components/ObjectList.vue' /* webpackChunkName: "components/object-list" */).then(c => wrapFunctional(c.default || c)),
  ObjectPicker: () => import('../../components/ObjectPicker.vue' /* webpackChunkName: "components/object-picker" */).then(c => wrapFunctional(c.default || c)),
  ObjectSelect: () => import('../../components/ObjectSelect.vue' /* webpackChunkName: "components/object-select" */).then(c => wrapFunctional(c.default || c)),
  PerfectScrollBar: () => import('../../components/PerfectScrollBar.vue' /* webpackChunkName: "components/perfect-scroll-bar" */).then(c => wrapFunctional(c.default || c)),
  ResizeSensor: () => import('../../components/ResizeSensor.vue' /* webpackChunkName: "components/resize-sensor" */).then(c => wrapFunctional(c.default || c)),
  SimpleList: () => import('../../components/SimpleList.vue' /* webpackChunkName: "components/simple-list" */).then(c => wrapFunctional(c.default || c)),
  TimePicker: () => import('../../components/TimePicker.vue' /* webpackChunkName: "components/time-picker" */).then(c => wrapFunctional(c.default || c)),
  Uploader: () => import('../../components/Uploader.vue' /* webpackChunkName: "components/uploader" */).then(c => wrapFunctional(c.default || c)),
  Formatter: () => import('../../components/formatter.js' /* webpackChunkName: "components/formatter" */).then(c => wrapFunctional(c.default || c)),
  QuillEditor: () => import('../../components/quillEditor.vue' /* webpackChunkName: "components/quill-editor" */).then(c => wrapFunctional(c.default || c)),
  Renderer: () => import('../../components/renderer.js' /* webpackChunkName: "components/renderer" */).then(c => wrapFunctional(c.default || c)),
  ChartsBarChart: () => import('../../components/charts/BarChart.vue' /* webpackChunkName: "components/charts-bar-chart" */).then(c => wrapFunctional(c.default || c)),
  ChartsFrequencyChart: () => import('../../components/charts/FrequencyChart.vue' /* webpackChunkName: "components/charts-frequency-chart" */).then(c => wrapFunctional(c.default || c)),
  ChartsLineChart: () => import('../../components/charts/LineChart.vue' /* webpackChunkName: "components/charts-line-chart" */).then(c => wrapFunctional(c.default || c)),
  ChartsPie: () => import('../../components/charts/Pie.vue' /* webpackChunkName: "components/charts-pie" */).then(c => wrapFunctional(c.default || c)),
  ChartsPieChart: () => import('../../components/charts/PieChart.vue' /* webpackChunkName: "components/charts-pie-chart" */).then(c => wrapFunctional(c.default || c)),
  ChartsUVIndex: () => import('../../components/charts/UVIndex.vue' /* webpackChunkName: "components/charts-u-v-index" */).then(c => wrapFunctional(c.default || c)),
  ChartsVisibility: () => import('../../components/charts/Visibility.vue' /* webpackChunkName: "components/charts-visibility" */).then(c => wrapFunctional(c.default || c)),
  ChartsChart: () => import('../../components/charts/chart.js' /* webpackChunkName: "components/charts-chart" */).then(c => wrapFunctional(c.default || c)),
  ChartsReactive: () => import('../../components/charts/reactive.js' /* webpackChunkName: "components/charts-reactive" */).then(c => wrapFunctional(c.default || c)),
  ChartsSalesByCategory: () => import('../../components/charts/salesByCategory.vue' /* webpackChunkName: "components/charts-sales-by-category" */).then(c => wrapFunctional(c.default || c)),
  DialogsAddWeatherCard: () => import('../../components/dialogs/AddWeatherCard.vue' /* webpackChunkName: "components/dialogs-add-weather-card" */).then(c => wrapFunctional(c.default || c)),
  StationMetricList: () => import('../../components/station/MetricList.vue' /* webpackChunkName: "components/station-metric-list" */).then(c => wrapFunctional(c.default || c)),
  StationRangePicker: () => import('../../components/station/RangePicker.vue' /* webpackChunkName: "components/station-range-picker" */).then(c => wrapFunctional(c.default || c)),
  StationGraphView: () => import('../../components/station/StationGraphView.vue' /* webpackChunkName: "components/station-graph-view" */).then(c => wrapFunctional(c.default || c)),
  SvgBaroSvg: () => import('../../components/svg/BaroSvg.vue' /* webpackChunkName: "components/svg-baro-svg" */).then(c => wrapFunctional(c.default || c)),
  SvgCoSvg: () => import('../../components/svg/CoSvg.vue' /* webpackChunkName: "components/svg-co-svg" */).then(c => wrapFunctional(c.default || c)),
  SvgRainSvg: () => import('../../components/svg/RainSvg.vue' /* webpackChunkName: "components/svg-rain-svg" */).then(c => wrapFunctional(c.default || c)),
  SvgSunmoonArcSvg: () => import('../../components/svg/SunmoonArcSvg.vue' /* webpackChunkName: "components/svg-sunmoon-arc-svg" */).then(c => wrapFunctional(c.default || c)),
  SvgSunmoonSvg: () => import('../../components/svg/SunmoonSvg.vue' /* webpackChunkName: "components/svg-sunmoon-svg" */).then(c => wrapFunctional(c.default || c)),
  SvgTrendSvg: () => import('../../components/svg/TrendSvg.vue' /* webpackChunkName: "components/svg-trend-svg" */).then(c => wrapFunctional(c.default || c)),
  SvgWindSvg: () => import('../../components/svg/WindSvg.vue' /* webpackChunkName: "components/svg-wind-svg" */).then(c => wrapFunctional(c.default || c)),
  WeatherAlertBadges: () => import('../../components/weather/AlertBadges.vue' /* webpackChunkName: "components/weather-alert-badges" */).then(c => wrapFunctional(c.default || c)),
  WeatherAlertCard: () => import('../../components/weather/AlertCard.vue' /* webpackChunkName: "components/weather-alert-card" */).then(c => wrapFunctional(c.default || c)),
  WeatherBaroCard: () => import('../../components/weather/BaroCard.vue' /* webpackChunkName: "components/weather-baro-card" */).then(c => wrapFunctional(c.default || c)),
  WeatherBattCard: () => import('../../components/weather/BattCard.vue' /* webpackChunkName: "components/weather-batt-card" */).then(c => wrapFunctional(c.default || c)),
  WeatherBtnGroup: () => import('../../components/weather/BtnGroup.vue' /* webpackChunkName: "components/weather-btn-group" */).then(c => wrapFunctional(c.default || c)),
  WeatherCardBase: () => import('../../components/weather/CardBase.ts' /* webpackChunkName: "components/weather-card-base" */).then(c => wrapFunctional(c.default || c)),
  WeatherCardComponent: () => import('../../components/weather/CardComponent.vue' /* webpackChunkName: "components/weather-card-component" */).then(c => wrapFunctional(c.default || c)),
  WeatherChannelCard: () => import('../../components/weather/ChannelCard.vue' /* webpackChunkName: "components/weather-channel-card" */).then(c => wrapFunctional(c.default || c)),
  WeatherCo2Card: () => import('../../components/weather/Co2Card.vue' /* webpackChunkName: "components/weather-co2-card" */).then(c => wrapFunctional(c.default || c)),
  WeatherCoCard: () => import('../../components/weather/CoCard.vue' /* webpackChunkName: "components/weather-co-card" */).then(c => wrapFunctional(c.default || c)),
  WeatherDetailTable: () => import('../../components/weather/DetailTable.vue' /* webpackChunkName: "components/weather-detail-table" */).then(c => wrapFunctional(c.default || c)),
  WeatherDeviceCard: () => import('../../components/weather/DeviceCard.vue' /* webpackChunkName: "components/weather-device-card" */).then(c => wrapFunctional(c.default || c)),
  WeatherEditCard: () => import('../../components/weather/EditCard.vue' /* webpackChunkName: "components/weather-edit-card" */).then(c => wrapFunctional(c.default || c)),
  WeatherExportCard: () => import('../../components/weather/ExportCard.vue' /* webpackChunkName: "components/weather-export-card" */).then(c => wrapFunctional(c.default || c)),
  WeatherExtendSubscriptionCard: () => import('../../components/weather/ExtendSubscriptionCard.vue' /* webpackChunkName: "components/weather-extend-subscription-card" */).then(c => wrapFunctional(c.default || c)),
  WeatherFooter: () => import('../../components/weather/Footer.vue' /* webpackChunkName: "components/weather-footer" */).then(c => wrapFunctional(c.default || c)),
  WeatherForecastCard: () => import('../../components/weather/ForecastCard.vue' /* webpackChunkName: "components/weather-forecast-card" */).then(c => wrapFunctional(c.default || c)),
  WeatherGradientBorder: () => import('../../components/weather/GradientBorder.vue' /* webpackChunkName: "components/weather-gradient-border" */).then(c => wrapFunctional(c.default || c)),
  WeatherHchoCard: () => import('../../components/weather/HchoCard.vue' /* webpackChunkName: "components/weather-hcho-card" */).then(c => wrapFunctional(c.default || c)),
  WeatherIndoorCard: () => import('../../components/weather/IndoorCard.vue' /* webpackChunkName: "components/weather-indoor-card" */).then(c => wrapFunctional(c.default || c)),
  WeatherLeakageCard: () => import('../../components/weather/LeakageCard.vue' /* webpackChunkName: "components/weather-leakage-card" */).then(c => wrapFunctional(c.default || c)),
  WeatherLeakageCardRow: () => import('../../components/weather/LeakageCardRow.vue' /* webpackChunkName: "components/weather-leakage-card-row" */).then(c => wrapFunctional(c.default || c)),
  WeatherLightCard: () => import('../../components/weather/LightCard.vue' /* webpackChunkName: "components/weather-light-card" */).then(c => wrapFunctional(c.default || c)),
  WeatherLightningCard: () => import('../../components/weather/LightningCard.vue' /* webpackChunkName: "components/weather-lightning-card" */).then(c => wrapFunctional(c.default || c)),
  WeatherOutdoorCard: () => import('../../components/weather/OutdoorCard.vue' /* webpackChunkName: "components/weather-outdoor-card" */).then(c => wrapFunctional(c.default || c)),
  WeatherOverviewCard: () => import('../../components/weather/OverviewCard.vue' /* webpackChunkName: "components/weather-overview-card" */).then(c => wrapFunctional(c.default || c)),
  WeatherPlanDialog: () => import('../../components/weather/PlanDialog.vue' /* webpackChunkName: "components/weather-plan-dialog" */).then(c => wrapFunctional(c.default || c)),
  WeatherPmCard: () => import('../../components/weather/PmCard.vue' /* webpackChunkName: "components/weather-pm-card" */).then(c => wrapFunctional(c.default || c)),
  WeatherRainCard: () => import('../../components/weather/RainCard.vue' /* webpackChunkName: "components/weather-rain-card" */).then(c => wrapFunctional(c.default || c)),
  WeatherServerCard: () => import('../../components/weather/ServerCard.vue' /* webpackChunkName: "components/weather-server-card" */).then(c => wrapFunctional(c.default || c)),
  WeatherSkyCard: () => import('../../components/weather/SkyCard.vue' /* webpackChunkName: "components/weather-sky-card" */).then(c => wrapFunctional(c.default || c)),
  WeatherSunmoonCard: () => import('../../components/weather/SunmoonCard.vue' /* webpackChunkName: "components/weather-sunmoon-card" */).then(c => wrapFunctional(c.default || c)),
  WeatherTodayCard: () => import('../../components/weather/TodayCard.vue' /* webpackChunkName: "components/weather-today-card" */).then(c => wrapFunctional(c.default || c)),
  WeatherUploadTemplate: () => import('../../components/weather/UploadTemplate.vue' /* webpackChunkName: "components/weather-upload-template" */).then(c => wrapFunctional(c.default || c)),
  WeatherUvCard: () => import('../../components/weather/UvCard.vue' /* webpackChunkName: "components/weather-uv-card" */).then(c => wrapFunctional(c.default || c)),
  WeatherWbgtCard: () => import('../../components/weather/WbgtCard.vue' /* webpackChunkName: "components/weather-wbgt-card" */).then(c => wrapFunctional(c.default || c)),
  WeatherDateArrow: () => import('../../components/weather/WeatherDateArrow.vue' /* webpackChunkName: "components/weather-date-arrow" */).then(c => wrapFunctional(c.default || c)),
  WeatherDatePicker: () => import('../../components/weather/WeatherDatePicker.vue' /* webpackChunkName: "components/weather-date-picker" */).then(c => wrapFunctional(c.default || c)),
  WeatherGraphGroup: () => import('../../components/weather/WeatherGraphGroup.vue' /* webpackChunkName: "components/weather-graph-group" */).then(c => wrapFunctional(c.default || c)),
  WeatherMap: () => import('../../components/weather/WeatherMap.vue' /* webpackChunkName: "components/weather-map" */).then(c => wrapFunctional(c.default || c)),
  WeatherWindCard: () => import('../../components/weather/WindCard.vue' /* webpackChunkName: "components/weather-wind-card" */).then(c => wrapFunctional(c.default || c))
}

for (const name in components) {
  Vue.component(name, components[name])
  Vue.component('Lazy' + name, components[name])
}
